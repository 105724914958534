import store from '../redux/store'
import IonIcon from '@reacticons/ionicons'
import { useNavigate, useLocation } from 'react-router-dom'

const LogoBar = ({ openMenu }) => {

  const navigate = useNavigate()
  const sessionData = store.getState().authReducer.sessionData

  return (
    <div id='logo-bar' className='flex alignitems-center justcont-spacebetween' style={{ gap: '14px' }}>
      <IonIcon onClick={() => navigate('/')} className='pointer' name='home-sharp' style={{ fontSize:'32px', color: '#eeeeee' }} />
      <div className='flex row alignitems-center' style={{ gap: '14px' }}>
        <svg
          className='pointer'
          style={{ fontSize:'32px', color: 'white' }}
          onClick={() => openMenu('qrScanner')}
          xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M8 21H4a1 1 0 0 1-1-1v-4a1 1 0 0 0-2 0v4a3 3 0 0 0 3 3h4a1 1 0 0 0 0-2Zm14-6a1 1 0 0 0-1 1v4a1 1 0 0 1-1 1h-4a1 1 0 0 0 0 2h4a3 3 0 0 0 3-3v-4a1 1 0 0 0-1-1ZM20 1h-4a1 1 0 0 0 0 2h4a1 1 0 0 1 1 1v4a1 1 0 0 0 2 0V4a3 3 0 0 0-3-3ZM2 9a1 1 0 0 0 1-1V4a1 1 0 0 1 1-1h4a1 1 0 0 0 0-2H4a3 3 0 0 0-3 3v4a1 1 0 0 0 1 1Zm8-4H6a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1ZM9 9H7V7h2Zm5 2h4a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1Zm1-4h2v2h-2Zm-5 6H6a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1Zm-1 4H7v-2h2Zm5-1a1 1 0 0 0 1-1a1 1 0 0 0 0-2h-1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1Zm4-3a1 1 0 0 0-1 1v3a1 1 0 0 0 0 2h1a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1Zm-4 4a1 1 0 1 0 1 1a1 1 0 0 0-1-1Z"/>
        </svg>
        <div className='shadow logout-button flex relative alignitems-center' onClick={() => openMenu('userOptions')}>
          {sessionData.userNickname}
        </div>
      </div>
    </div>
  )
}

export default LogoBar
